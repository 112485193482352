import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { closeModal } from './modals';
import Slide from '@material-ui/core/Slide';
import { TakiPopups } from 'taki-popups-plugin-v2';

const initialState = {
  user: [],
  status: 'idle',
  error: null,
  isAdminTeacher: false,
  details: null,
};

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  let data;
  try {
    const response = await axios.get(`/instructor/me`);

    data = await response?.data;

    if (response?.status === 200) {
        const email = data?.payload?.email;
        const teacherSubjects = data?.payload?.instructor_subjects.map((el) => {
          return `${el.subject?.division?.name}-${el.subject?.name}`;
        });
        TakiPopups({
            memberId: data?.payload?.id,
            appId: "672ddaa1240f36f5317f652e",
            name: `${data?.payload?.name} ${data?.payload?.last_name}`,
            meta_data: {
                teacher_subjects: teacherSubjects,
                domaineName: window.location.hostname,
                email: email ? email.toString() : 'no-email@gmail.com',
                phone: data?.payload?.phone,
            }
          
        });
      return data?.payload;
    }
    if (response?.status === 401) {
      localStorage.removeItem('token');
      delete axios.defaults.headers.common.Authorization;
      window.location.reload();
    }
    throw new Error(response?.statusText);
  } catch (err) {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
    window.location.reload();

    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const editEmail = createAsyncThunk('user/editEmail', async (query, thunkAPI) => {
  let data;
  try {
    const response = await axios.put(`/instructor/update`, query);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('edit-email-modal'));
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(
      enqueueSnackbar({
        message: err.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          TransitionComponent: Slide,
        },
      })
    );
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const editPassword = createAsyncThunk('user/editPassword', async (query, thunkAPI) => {
  let data;
  try {
    const response = await axios.put(`/instructor/change-password`, query);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('edit-password-modal'));
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(
      enqueueSnackbar({
        message: err.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          TransitionComponent: Slide,
        },
      })
    );
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUser.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.user = action.payload;
      state.isAdminTeacher = action?.payload.is_admin_teacher;
      const email = action?.payload?.email;
      const teacherSubjects = action?.payload?.instructor_subjects.map((el) => {
        return `${el.subject?.division?.name}-${el.subject?.name}`;
      });
      const details = {
        memberId: action.payload?.id,
        appId: "672ddaa1240f36f5317f652e",
        teacher_subjects: teacherSubjects,
        domaineName: window.location.hostname,
        name: `${action?.payload?.name} ${action?.payload?.last_name}`,
        email: email ? email.toString() : 'no-email@gmail.com',
        phone: action?.payload?.phone,
      };
      //TakiPopups(details);

      state.details = details;

    },
    [fetchUser.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [editEmail.fulfilled]: (state, action) => {
      state.user.email = action.payload.email;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
