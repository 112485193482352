import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dialog: {
    padding: '0px',
    margin: '0px',
  },
  dialogTitle: {
    padding: '0px 20px',
    margin: '0px',
  },
  dialogContent: {
    padding: '0 20px 20px 20px',
    margin: '0px',
  },
  icon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    color: '#2BA7DF',
  },
  dialogContentHeader: {
    marginBottom: '20px',
    justifyContent: 'space-between',

  },
  reportButton: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
    padding: "5px 10px",
    borderRadius: "15px",
    border: "0px"

  },
  iconUndo: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    color: '#2BA7DF',
    marginRight: '12px',
  },
  intervaleContainer: { display: 'flex', justifyItems: 'center' },
}));

export default useStyles;
