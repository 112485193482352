import React, { useEffect } from 'react';

import { fetchUser } from '../../slices/user';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import TableParams from '../TableParams';
import { Grid, Icon, TableCell, TableRow } from '@material-ui/core';
import useCell from '../../components/TableParams/style';
import useStyles from '../../pages/Incomes/SelectIncomesYearDropdown/style';
import { headersRowIncomes as headersRow, months } from '../../utilities/constants';

const PenaltyRepportsModal = ({ id, open, handleClose, data, ...rest }) => {
    console.log("zxcxcc", data)
    const classes = useStyles();
    const cellClasses = useCell();

    const dispatch = useDispatch();
    const { user, status, error } = useSelector((state) => state.user);
    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        if (status === 'idle' && open === true) {
            dispatch(fetchUser());
        }
    }, [open, status, dispatch]);

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(id)}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="ta-modal add-video-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <span className="label">Penalty Repports</span>
                <span className="close-btn" onClick={() => handleClose(id)}>
                    Fermer
                </span>
            </DialogTitle>
            <DialogContent className="ta-modal-content-scroll">
                <Grid container direction="column" >
                    <TableParams headersRow={headersRow} minWidth="488px">
                        {data?.penalty?.payload?.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell align="left" className={cellClasses.cell}>
                                    {row.month}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableParams>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

PenaltyRepportsModal.propTypes = {};

export default PenaltyRepportsModal;
