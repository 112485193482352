import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  icon: {
    position: 'absolute',
    width: '16px',
    height: '16px',
    top: '6px',
    left: '14px',
    color: '#A2A2A7',
  },
  chevron: {
    position: 'absolute',
    width: '26px',
    height: '26px',
    top: '2px',
    right: '8px',
    color: '#A2A2A7',
  },
});

export default useStyles;
