import React, { createRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { serialize } from 'object-to-formdata';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Box, FormHelperText, Typography, Button } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TelegramIcon from '@material-ui/icons/Telegram';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import * as dayjs from 'dayjs';
import CircularProgress from '@material-ui/core/CircularProgress';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import bytesToSize from '../../utilities/bytesToSize';
import { uploadAttachmentsToEvent, deleteAttachmentsFromEvent, getBBBSessionUrl } from '../../slices/events';
import ImPdf from '../../assets/img/icons/pdf.svg';
import { sliceLongStrings } from '../../utilities/methods';

const filesInputRef = createRef();
const openFilesInput = () => {
  if (filesInputRef.current) {
    filesInputRef.current.click();
  }
};

const Event = ({ id, open, handleClose, data, ...rest }) => {
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const { uploadedAttachments, status, deleteAttachmentStatus } = useSelector(
    (state) => state.events
  );
  const [files, setFiles] = useState([]);
  const [showButton, setShowButton] = useState(false);
  console.log(data)
  useEffect(() => {
    const checkTime = () => {
      const currentTime = new Date();
      const eventStartTime = new Date(data?.event?.start_date);

      const timeDifference = eventStartTime - currentTime;
      const fiveMinutes = 9 * 60 * 1000;

      setShowButton(
        data?.event?.event?.email?.includes("live")
      );
    };

    checkTime();
    if (showButton === false) {
      const interval = setInterval(checkTime, 1000);
      return () => clearInterval(interval);

    }

  }, [data?.event]);
  useEffect(() => {
    if (data?.event?.files) {
      setFiles(data?.event?.files);
    }
  }, [data?.event]);

  const handleDeleteFile = (contentFileId) => {
    dispatch(deleteAttachmentsFromEvent({ contentFileId }))
      .then(() => {
        setFiles((prevFiles) => prevFiles.filter(file => file.content_files[0].id !== contentFileId));
      })
      .catch((err) => {
        console.error('Failed to delete file:', err);
      });
  };
  const handleRequestBBBSession = (contentEventId) => {
    dispatch(getBBBSessionUrl({ contentEventId }))
      .then((data) => {
        console.log(data)
      })
      .catch((err) => {
        console.error('Failed to start session', err);
      });
  };
  let content;
  if (data) {
    const { event } = data;

    content = (
      <>
        <div className="badges">
          {/* <Chip label="Séance direct publiée" className="purple" /> */}
          {event?.active === true ? (
            <Chip label="Séance directe active" className="purple" />
          ) : (
            <Chip label="Séance directe non active" className="danger" />
          )}
          {dayjs(event?.start_date).isBefore(dayjs()) && (
            <Chip label="Séance directe terminée" className="primary" />
          )}
          {event?.isDraft === false ? (
            <Chip label="Séance directe publiée" className="purple" />
          ) : (
            <Chip label="Séance directe non publiée" className="danger" />
          )}
          {dayjs(event?.start_date).isBefore(dayjs()) && (
            <Chip label="Séance directe terminée" className="primary" />
          )}
        </div>
        <span className="title">{event?.name}</span>
        <p className="description">{event?.description}</p>
        <Divider />
        <div className="split-childs">
          <div className="list">
            {event?.subjects?.length > 0 && (
              <>
                <span className="label">Matières :</span>
                {event?.subjects.map((item, index) => {
                  return (
                    <span key={index}>
                      <FiberManualRecordIcon /> {`${item.division.name} - ${item.name}`}
                    </span>
                  );
                })}
              </>
            )}
            <span className="label"></span>
            {event?.levels?.length > 0 && (
              <>
                <span className="label" style={{ marginTop: 6 }}>
                  Niveaux :
                </span>
                {event?.levels.map((item, index) => {
                  return (
                    <span key={index}>
                      <FiberManualRecordIcon /> {item.name}
                    </span>
                  );
                })}
              </>
            )}
          </div>
          <div className="list">
            <span className="label">Dates de la séance :</span>
            <span>
              <FiberManualRecordIcon /> Date de début:
              <span className="text-muted" style={{ marginLeft: 4 }}>
                {dayjs(event?.start_date).format('DD/MM/YYYY - HH:mm')}
              </span>
            </span>
            <span>
              <FiberManualRecordIcon /> Date de fin:
              <span className="text-muted" style={{ marginLeft: 4 }}>
                {dayjs(event?.end_date).format('DD/MM/YYYY - HH:mm')}
              </span>
            </span>
            {event?.groups.length > 0 && (
              <>
                <span className="label">Groupes :</span>
                {event?.groups.map((item, index) => {
                  return (
                    <span key={index}>
                      <FiberManualRecordIcon /> {`${item.name}`}
                    </span>
                  );
                })}
              </>
            )}
          </div>
        </div>
        <Divider />
        <TextField
          id="outlined-helperText"
          defaultValue={event?.event?.url}
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          disabled
          InputProps={{
            endAdornment: (
              <a className="link primary" href={event?.event?.url} target="_blank" rel="noreferrer">
                <InputAdornment position="end">
                  <TelegramIcon />
                </InputAdornment>
              </a>
            ),
          }}
        />
        {showButton && !event?.event?.url && <button className='request-url-button' onClick={() => handleRequestBBBSession(event?.event?.id)}>Request session link</button>
        }
        {!event?.event?.url.includes('zoom') && !event?.event?.url.includes('bigbluebutton') && event?.event?.url && (
          <>
            <span className="text-muted">
              <span className="strong">Email :</span> {event?.event?.email}
            </span>
            <span className="text-muted">
              <span className="strong">Mot de passe :</span> {event?.event?.password}
            </span>
          </>
        )}

        <Divider />
        <div>
          <div>
            <Formik
              initialValues={{
                files: [],
                description: event?.description,
                name: event?.isEventTitleApproved === false ? event?.titleToApprove : event?.name
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .transform((value) => (value === null ? '' : value))
                  .required('Title is required')
                  .min(3, 'Name must be at least 3 characters')
                  .max(2000, 'Name must be at most 2000 characters'),
                files: Yup.array(),
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  const dataToSend = {
                    contentFiles:
                      values.files.length > 0
                        ? values.files.map((item, index) => {
                          let fileBinary = {
                            // position: index,
                            file: {
                              name: item.name,
                              file: item,
                              attachment: true,
                              homework: false,
                              document: false,
                              solution: false,
                              marks: false,
                            },
                          };
                          return fileBinary;
                        })
                        : values.files,

                    description: values?.description ? values.description : event?.description,
                    name: event?.event.traineeship && values?.name ? event?.name : values.name,
                    titleToApprove: event?.event.traineeship && values?.name ? values.name : '',
                    isEventTitleApproved: event?.event?.traineeship === true ? 0 : 1,

                  };
                  const formData = serialize(dataToSend, { indices: true });
                  dispatch(
                    uploadAttachmentsToEvent({
                      values: formData,
                      contentId: data.event.event.id,
                    })
                  );

                  if (isMountedRef.current) {
                    setSubmitting(false);
                  }
                } catch (err) {
                  console.error(err);
                  if (isMountedRef.current) {
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }
              }}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                resetForm,
                setFieldValue,
                setFieldTouched,
              }) => (
                <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
                  <span className="label">Détails:</span>
                  <br />
                  <Typography variant="body2" color="textSecondary">
                    Ajouter un titre <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <section>
                    <TextField
                      multiline
                      fullWidth
                      variant="outlined"
                      //helperText={event.isEventTitleApproved === false && "Le titre sera approuvé par l\'administrateur"}
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                    />
                  </section>
                  {touched.name && errors.name && (
                    <span style={{ color: "red" }}>
                      {errors.name}
                    </span>
                  )}
                  <br />
                  <Typography variant="body2" color="textSecondary">
                    Ajouter une description
                  </Typography>
                  <section>
                    <TextField
                      multiline
                      fullWidth
                      variant="outlined"
                      name="description"
                      onChange={handleChange}
                      value={values.description}
                    />

                  </section>
                  <br />
                  <span className="label">Travail à faire :</span>
                  <br />
                  <Typography variant="body2" color="textSecondary">
                    Ajouter des fichiers
                  </Typography>
                  <section
                    className={
                      touched.files && errors.files ? 'dropzone event error' : 'dropzone event'
                    }
                  >
                    <div>
                      <Button
                        className="ta-btn dark btn-rounded "
                        startIcon={<AttachFileIcon />}
                        type="button"
                        onClick={openFilesInput}
                      >
                        Choisir des fichiers
                      </Button>
                      <div className="files-list">
                        {values.files && values.files.length > 0
                          ? `${values.files.length} ${values.files.length > 1
                            ? `fichiers sélectionnés`
                            : `fichier sélectionné`
                          } - ${bytesToSize(
                            values.files.reduce((accumulator, currentValue) => {
                              return accumulator + currentValue.size;
                            }, 0)
                          )}`
                          : `aucun fichier sélectionné`}
                      </div>
                    </div>
                    {values.files.length > 0 && (
                      <Box className="ta-btns-group">
                        <Button
                          disabled={isSubmitting}
                          type="reset"
                          className="ta-btn btn-rounded only-icon"
                          onClick={resetForm}
                        >
                          <CloseIcon />
                        </Button>
                      </Box>
                    )}
                    <input
                      type="file"
                      ref={filesInputRef}
                      style={{ display: 'none' }}
                      name="files"
                      onClick={() => {
                        setFieldTouched('files', true);
                      }}
                      onChange={(e) => {
                        setFieldValue('files', [...e.target.files]);
                      }}
                      accept="application/pdf"
                      multiple
                    />
                  </section>
                  {touched.files && errors.files && (
                    <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                      {errors.files}
                    </span>
                  )}
                  < div style={{ gap: '1rem', marginBottom: '1rem' }}>
                    {files?.length > 0 && (
                      <div className="files-container">
                        {files
                          .slice()
                          .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
                          .map((file, index) => {
                            return (
                              <div key={index} className="file">
                                <a className="title" href={file.url} target="_blank" rel="noreferrer">
                                  <img alt="img" src={ImPdf} />
                                  <span>{sliceLongStrings(file.name, 42)}</span>
                                </a>
                                <Button
                                  startIcon={<DeleteIcon />}
                                  className="ta-btn primary btn-rounded"
                                  onClick={() => handleDeleteFile(file.content_files[0].id)}
                                  key={file.content_files[0].id}
                                >
                                  Supprimer
                                </Button>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>

                  <Box className="ta-btns-group">
                    <Button
                      disabled={(status === 'loading' || uploadedAttachments.status === 'loading') ? true : false}
                      type="submit"
                      className="ta-btn primary btn-rounded only-text"
                    >
                      {(status === 'loading' || uploadedAttachments.status === 'loading') ? "Mis à jours ..." : "Ajouter"}
                      {(status === 'loading' || uploadedAttachments.status === 'loading') && <CircularProgress size={20} style={{ margin: '0 auto' }} />}
                    </Button>
                  </Box>
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </form>
              )}
            </Formik>
          </div>

          {/* <div className="list">
            <span className="strong">Ajouter des fichiers après de la séance</span>
          </div> */}
        </div>
      </>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Détails d'évènement</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll task-event-modal-container">
        {content}
      </DialogContent>
    </Dialog>
  );
};

Event.propTypes = {};

export default Event;
