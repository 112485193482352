import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { months, initialNumbersCurrentMonth } from '../utilities/constants';
import { convertMonth } from '../utilities/methods';
import { getSum } from '../utilities/getSum';
import { formatNumber } from '../utilities/formatNumber';
import { enqueueSnackbar } from './notifier';
import Slide from '@material-ui/core/Slide';
import { closeModal } from './modals';

const initialState = {
  yearIncomes: {
    numbersCurrentMonth: initialNumbersCurrentMonth,
    data: [],
    status: 'idle',
    error: null,
  },
  monthIncomes: {
    data: [],
    status: 'idle',
    error: null,
  },
  penaltyRepports: {
    data: [],
    status: 'idle',
    error: null,
  },
  monthDetailsDirect: {
    data: [],
    status: 'idle',
    error: null,
  },
  MonthDetailsDefaults: {
    title: '',
    data: [],
    status: 'idle',
    error: null,
  },
  currentDataDate: {
    month: 1,
    year: new Date().getFullYear(),
  },
};

export const fetchIncomes = createAsyncThunk('incomes/fetchIncomes', async (year) => {
  let data;
  try {
    const response = await axios.get(`/instructor/incomes/year/${year}`);

    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchMonthIncomes = createAsyncThunk('incomes/fetchMonthIncomes', async (params) => {
  let data;
  try {
    const response = await axios.get(
      `/instructor/incomes/month/${params.month}/year/${params.year}`
    );
    data = await response.data;

    if (response.status === 200) {
      return { data, params };
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchMonthDetailsDirect = createAsyncThunk(
  'incomes/fetchMonthDetailsDirect',
  async (params) => {
    let data;
    try {
      const response = await axios.get(
        `/instructor/incomes/live-sessions/month/${params.month}/year/${params.year}`
      );

      data = await response.data;
      if (response.status === 200) {
        return { data, params };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchPenaltyRepports = createAsyncThunk(
  'incomes/fetchPenaltyRepports',
  async (params) => {
    let data;
    try {
      const response = await axios.get(
        `/instructor/event-penalty-reports`
      );

      data = await response.data;
      if (response.status === 200) {
        return { data, params };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);


export const fetchMonthDetailsDefaults = createAsyncThunk(
  'incomes/fetchMonthDetailsDefaults',
  async ({ event, title }) => {
    let data;
    try {
      const response = await axios.get(`/instructor/incomes/penalties/event/${event}`);

      data = await response.data;
      if (response.status === 200) {
        return { data, title };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const penaltyReports = createAsyncThunk(
  'incomes/penaltyReports',
  async (payload, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/instructor/event-penalty-reports`,
        payload
      );

      data = await response;
      if ((response.status = 200)) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: "pénalité signalée avec succès",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        thunkAPI.dispatch(closeModal('penalty-report-modal'));
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: 'incomes',
  initialState,
  reducers: {
    changeYear: (state, action) => {
      state.currentDataDate.year = action.payload;
    },
  },
  extraReducers: {
    [fetchIncomes.pending]: (state) => {
      state.yearIncomes.status = 'loading';
    },
    [fetchIncomes.fulfilled]: (state, action) => {
      state.yearIncomes.status = 'succeeded';

      const numbersCurrentMonthAPI = action.payload.data.numbersCurrentMonth;
      const dataAPI = action.payload.data?.incomes || null;
      let data = null;

      if (dataAPI) {
        data = months.map((month, index) => ({
          id: months[index].id,
          month: months[index].name,
          balance: dataAPI[months[index].id].solde,
          fault: dataAPI[months[index].id].default,
          finalBlance: dataAPI[months[index].id].finalSolde,
        }));
      }

      state.yearIncomes.data = data;

      const formattedData = [];
      const initialNumbersArray = JSON.parse(JSON.stringify(initialNumbersCurrentMonth));

      Object.keys(numbersCurrentMonthAPI).forEach((property) => {
        const value = numbersCurrentMonthAPI[property];
        const formattedObject = {
          title: property,
          id: property,
          value: typeof value === 'object' ? formatNumber(getSum(value)) : formatNumber(value),
          unit: '',
        };

        formattedData.push(formattedObject);
      });

      state.yearIncomes.numbersCurrentMonth = initialNumbersArray.map((item) =>
        formattedData.find((el) => el.id === item.id)
          ? { ...item, value: formattedData.find((el) => el.id === item.id).value }
          : item
      );
    },
    [fetchIncomes.rejected]: (state, action) => {
      state.yearIncomes.status = 'failed';
      state.yearIncomes.error = action.payload;
    },
    [fetchMonthIncomes.pending]: (state) => {
      state.monthIncomes.status = 'loading';
    },
    [fetchMonthIncomes.fulfilled]: (state, action) => {
      state.monthIncomes.status = 'succeeded';
      const dataAPI = action.payload.data.data.incomes;
      const monthNumber = convertMonth(action.payload.params.month);
      const data = {
        info: {
          month: months[monthNumber].name,
          year: action.payload.params.year,
          totalBalance: action.payload.data.data.total,
        },
      };
      const keys = [
        { id: 'liveSessions', articleName: 'Séan. en direct', unit: null },
        { id: 'videoSilver', articleName: 'vidéos silver', unit: '/mn' },
        { id: 'videoFree', articleName: 'vidéos gratuites', unit: '/mn' },
        { id: 'magazine', articleName: 'Magazines', unit: null },
        { id: 'correctedExam', articleName: 'Correct. examen', unit: null },
        { id: 'qcm', articleName: 'Qcm', unit: null },
        { id: 'forum', articleName: 'Forum', unit: null },
      ];
      keys.forEach((key1, index) => {
        const nestedKeys = Object.keys(dataAPI[key1.id]);

        if (nestedKeys.length > 0) {
          const unitPrices = nestedKeys.map((key) => ({
            title: key,
            unitPrice: dataAPI[key1.id][key].unitPrice,
          }));
          const value = nestedKeys.reduce(
            (prev, key2) => ({
              totalPrice:
                prev.totalPrice +
                dataAPI[key1.id][key2].quantity * dataAPI[key1.id][key2].unitPrice,
              quantity: prev.quantity + dataAPI[key1.id][key2].quantity,
            }),
            { quantity: 0, totalPrice: 0 }
          );
          keys[index].quantity = value.quantity;
          keys[index].totalPrice = value.totalPrice;
          keys[index].unitPrices = unitPrices;
        } else {
          keys[index].quantity = 0;
          keys[index].totalPrice = 0;
          keys[index].unitPrices = [];
        }
      });
      data.data = keys;
      const defaults = Object.keys(dataAPI.liveSessions).reduce(
        (prev, key) => dataAPI.liveSessions[key].default + prev,
        0
      );
      data.data[0].default = defaults;
      state.monthIncomes.data = data;
      state.currentDataDate = { ...action.payload.params };
    },
    [fetchMonthIncomes.rejected]: (state, action) => {
      state.monthIncomes.status = 'failed';
      state.monthIncomes.error = action.payload;
    },
    [fetchMonthDetailsDirect.pending]: (state) => {
      state.monthDetailsDirect.status = 'loading';
    },
    [fetchMonthDetailsDirect.fulfilled]: (state, action) => {
      state.monthDetailsDirect.status = 'succeeded';
      state.monthDetailsDirect.data = action.payload.data;
    },
    [fetchMonthDetailsDirect.rejected]: (state, action) => {
      state.monthDetailsDirect.status = 'failed';
      state.monthDetailsDirect.error = action.error.message;
    },

    [fetchPenaltyRepports.pending]: (state) => {
      state.penaltyRepports.status = 'loading';
    },
    [fetchPenaltyRepports.fulfilled]: (state, action) => {
      state.penaltyRepports.status = 'succeeded';
      state.penaltyRepports.data = action.payload.data;
    },
    [fetchPenaltyRepports.rejected]: (state, action) => {
      state.penaltyRepports.status = 'failed';
      state.penaltyRepports.error = action.error.message;
    },

    [fetchMonthDetailsDefaults.pending]: (state) => {
      state.MonthDetailsDefaults.status = 'loading';
    },
    [fetchMonthDetailsDefaults.fulfilled]: (state, action) => {
      const { data } = action.payload.data;
      state.MonthDetailsDefaults.status = 'succeeded';
      state.MonthDetailsDefaults.title = action.payload.title;
      state.MonthDetailsDefaults.data = data
        ?.filter((defaultTable) => defaultTable?.details?.length > 0)
        .map((defaultTable) => ({
          ...defaultTable,
          totalDefaults: defaultTable.details.reduce(
            (acc, current) => (current?.active ? current?.points : acc),
            0
          ),
        }));
    },
    [fetchMonthDetailsDefaults.rejected]: (state, action) => {
      state.MonthDetailsDefaults.status = 'failed';
      state.MonthDetailsDefaults.error = action.error.message;
    },
  },
});

export const reducer = slice.reducer;
export const { changeYear } = slice.actions;

export default slice;
