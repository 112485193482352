import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { TextField, Box, FormHelperText, Typography, Switch } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { penaltyReports } from '../../slices/incomes';

const AddPenaltyReport = ({ id, open, handleClose, data, ...rest }) => {
    const dispatch = useDispatch();

    const isMountedRef = useIsMountedRef();


    return (
        <Dialog
            open={open}
            onClose={() => handleClose(id)}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="ta-modal add-video-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <span className="label">Signaler Pénalité </span>
                <span className="close-btn" onClick={() => handleClose(id)}>
                    Fermer
                </span>
            </DialogTitle>
            <DialogContent className="ta-modal-content-scroll">
                <Formik
                    initialValues={{
                        description: '',
                        submit: null,
                    }}
                    validationSchema={Yup.object().shape({
                        description: Yup.string().required('description is required'),
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            dispatch(
                                penaltyReports({

                                    description: values.description,
                                    eventPenalty: data.penalty

                                })
                            );


                            if (isMountedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);

                            }
                        } catch (err) {
                            console.error(err);
                            if (isMountedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        resetForm,
                        setFieldValue,
                        setTouched,
                    }) => (
                        <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
                            <Typography variant="body2" color="textSecondary" className="label">
                                Description
                            </Typography>
                            <TextField
                                className="input"
                                error={Boolean(touched.description && errors.description)}
                                fullWidth
                                autoFocus
                                helperText={touched.description && errors.description}
                                // label="Title"
                                margin="normal"
                                name="description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.description}
                                variant="outlined"
                                size="small"
                            />

                            {errors.submit && (
                                <Box mt={3}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}
                            <Box mt={2} className="ta-btns-group">
                                <Button
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="ta-btn primary btn-rounded btn-lg"
                                >
                                    Confirmer
                                </Button>
                                <Button
                                    disabled={isSubmitting}
                                    type="reset"
                                    className="ta-btn btn-rounded btn-lg"
                                    onClick={resetForm}
                                >
                                    Annuler
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};



export default AddPenaltyReport;
